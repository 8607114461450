import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="bg-purple-700 min-h-screen text-white">
      <header className="p-4 flex justify-between items-center md:justify-start">
        <Link to="/">
          <img src="/img/logo.png" alt="CHAMA Logo" className="h-10 md:ml-8" />
        </Link>
      </header>

      <main className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
        <p className="text-sm text-gray-300 mb-2">Last Updated: 01/09/2024</p>

        <p className="mb-6">
          Welcome to Chama! These Terms of Service (“Terms”) govern your use of our website and services, including the curation of real estate listings and the generation of personalized PDF catalogs. By accessing or using our services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.
        </p>

        <h2 className="text-2xl font-bold mb-4">1. Use of Services</h2>
        <h3 className="text-xl font-bold mb-2">a. Eligibility</h3>
        <p className="mb-4">
          You must be at least 18 years old to use our services. By using our services, you represent and warrant that you meet this age requirement.
        </p>

        <h3 className="text-xl font-bold mb-2">b. Account Registration</h3>
        <p className="mb-6">
          To use certain features of our service, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </p>

        <h2 className="text-2xl font-bold mb-4">2. Information We Collect</h2>
        <p className="mb-6">
          As part of providing our services, we collect and use certain information. Please refer to our Privacy Policy for details on the information we collect and how we use it.
        </p>

        <h2 className="text-2xl font-bold mb-4">3. Service Availability</h2>
        <p className="mb-6">
          We strive to maintain the availability of our services; however, we do not guarantee that the services will be available at all times. We may temporarily suspend or restrict access to our services for maintenance, updates, or other reasons without notice or liability.
        </p>

        <h2 className="text-2xl font-bold mb-4">4. Prohibited Conduct</h2>
        <p className="mb-6">
          You agree not to engage in any of the following prohibited activities:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Unauthorized Use: Accessing or using the service in any way that is not expressly permitted by these Terms.</li>
          <li>Disruption: Engaging in any activity that disrupts or interferes with the functioning of our services or the servers and networks used to provide them.</li>
          <li>Misrepresentation: Providing false or misleading information, including during account registration.</li>
          <li>Violation of Laws: Using the service in any manner that violates applicable laws or regulations.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">5. Intellectual Property</h2>
        <p className="mb-6">
          All content and materials provided by Chama, including but not limited to text, graphics, logos, and software, are the intellectual property of Chama or our licensors. You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the services for your personal and non-commercial use. You may not reproduce, distribute, modify, or create derivative works from any content without our prior written permission.
        </p>

        <h2 className="text-2xl font-bold mb-4">6. Limitation of Liability</h2>
        <p className="mb-6">
          To the fullest extent permitted by law, Chama shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the service, whether based on warranty, contract, tort (including negligence), or any other legal theory, even if Chama has been advised of the possibility of such damages.
        </p>

        <h2 className="text-2xl font-bold mb-4">7. Indemnification</h2>
        <p className="mb-6">
          You agree to indemnify, defend, and hold harmless Chama, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in any way connected with your access to or use of the service or your violation of these Terms.
        </p>

        <h2 className="text-2xl font-bold mb-4">8. Modifications to the Terms</h2>
        <p className="mb-6">
          We may update or modify these Terms from time to time at our discretion. If we make material changes, we will notify you by updating the "Last Updated" date at the top of these Terms and, if applicable, through other communication channels. Your continued use of the services after the changes take effect constitutes your acceptance of the revised Terms.
        </p>

        <h2 className="text-2xl font-bold mb-4">9. Termination</h2>
        <p className="mb-6">
          We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without prior notice, if we believe you have violated these Terms or if we decide to discontinue the service.
        </p>

        <h2 className="text-2xl font-bold mb-4">10. Governing Law</h2>
        <p className="mb-6">
          These Terms and any disputes arising out of or related to them shall be governed by and construed in accordance with the laws of the United Arab Emirates, without regard to its conflict of law principles.
        </p>

        <h2 className="text-2xl font-bold mb-4">11. Contact Us</h2>
        <p className="mb-6">
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <p className="mb-6">
          Chama<br />
          Email: chamadubai@estaters.online<br />
          Address: Golden Mile 8 - 8 Palm Jumeirah Road - Palm Jumeirah - Dubai
        </p>
      </main>

      <footer className="bg-purple-800 py-4 text-center">
        <p>&copy; 2024 Chama. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsOfService;
