import React, { useState, useEffect } from 'react';
import { ArrowRight, BarChart2, PhoneOff, FileText, Building2, Brain, Home, Cpu, DollarSign } from 'lucide-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const LandingPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isHidden, setIsHidden] = useState(false);
  const [isPastFreeSection, setIsPastFreeSection] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);

      const freeSection = document.querySelector('.free-section');
      if (freeSection) {
        const rect = freeSection.getBoundingClientRect();
        setIsPastFreeSection(rect.top <= window.innerHeight && rect.bottom >= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="bg-purple-700 min-h-screen text-white overflow-hidden relative">
      <header className="p-4 flex justify-between items-center md:justify-start">
        <img src="/img/logo.png" alt="CHAMA Logo" className="h-10 md:ml-8" />
        <nav className="md:ml-auto hidden md:block">
          <a href="https://app.estaters.online/">
            <button className="bg-pink-200 text-purple-700 px-8 py-2 rounded-full hover:bg-pink-300 transition-colors">
              Try for Free
            </button>
          </a>
        </nav>
      </header>

      <main className="container mx-auto px-4">
        {/* Первый экран */}
        <section className="py-20 flex flex-col md:flex-row items-center text-center md:text-left relative">
          <div className={`md:w-1/2 transition-all duration-1000 transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'} z-20`}>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 relative z-20 leading-relaxed lg:leading-[1.15]">
              Get a customized property catalog of investment properties in Dubai in{' '}
              <span className="bg-white text-black px-2">3 minutes</span>
            </h1>
            <a href="https://app.estaters.online/">
              <button className="bg-pink-200 text-purple-700 px-12 py-3 rounded-full text-lg font-semibold flex items-center mx-auto md:mx-0 hover:bg-pink-300 transition-colors relative z-20 md:w-auto w-full">
                Try Now <ArrowRight className="ml-2" />
              </button>
            </a>
          </div>
          <div className="relative mt-8 md:mt-0 md:w-1/2 flex justify-center">
  <div className="floating-image relative">
    <img
      src="/img/mockup-hero.png"
      alt="Property Catalog"
      className="w-full md:w-auto h-auto md:h-[48rem]"
      style={{ maxWidth: '800px', maxHeight: '600px' }}
    />
    <div className="glowing-background"></div>
  </div>
</div>




          {/* Анимированные иконки */}
          <IconAnimation Icon={Building2} className="top-10 left-5" delay={0} />
          <IconAnimation Icon={Brain} className="top-20 right-5" delay={200} />
          <IconAnimation Icon={Home} className="bottom-10 left-1/4" delay={400} />
          <IconAnimation Icon={Cpu} className="bottom-20 right-1/4" delay={600} />
          <IconAnimation Icon={DollarSign} className="top-1/3 left-1/2 transform -translate-x-1/2" delay={800} />
        </section>

        {/* Блок Benefits */}
        <section className="py-16">
          <h2 className="text-3xl font-bold mb-10 text-center">Benefits</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <BenefitCard
              icon={<PhoneOff />}
              title="No realtors or annoying phone calls"
              description="Browse properties without interruptions"
            />
            <BenefitCard
              icon={<FileText />}
              title="Description and features of each object"
              description="Detailed information at your fingertips"
            />
            <BenefitCard
              icon={<BarChart2 />}
              title="Yield forecast based on data"
              description="Make informed investment decisions"
            />
          </div>
        </section>

        {/* Блок How are the metrics forecasted? */}
        <section className="py-16 bg-purple-600 rounded-lg p-8">
          <h2 className="text-3xl font-bold mb-6">How are the metrics forecasted?</h2>
          <p className="text-lg">
            We have been using official data from the DLD website on transactions since 2001. This data is run through the Prophet model. It is then adjusted by an analyst based on global economic trends.
          </p>
        </section>

        {/* Блок Our Partners */}
        <section className="py-16 bg-purple-700 text-center">
          <h2 className="text-3xl font-bold mb-8">Our Partners</h2>
          <div className="overflow-hidden flex justify-center">
            <div className="flex md:space-x-16 space-x-8">
              <img src="/img/dld-partner-logo.png" alt="Partner 1" className="h-24 md:h-32" />
              <img src="/img/kf-partner-logo.png" alt="Partner 2" className="h-24 md:h-32" />
              <img src="/img/savills-partner-logo.png" alt="Partner 3" className="h-24 md:h-32" />
              <img src="/img/jll-partner-logo.png" alt="Partner 4" className="h-24 md:h-32" />
            </div>
          </div>
        </section>

        {/* Блок Absolutely free */}
        <section className="py-16 text-center free-section">
          <h2 className="text-3xl font-bold mb-6">Absolutely free</h2>
          <p className="text-xl mb-8">Try it. The product is now available for free.</p>
          <a href="https://app.estaters.online/">
            <button className="bg-pink-200 text-purple-700 px-6 py-3 rounded-full text-lg font-semibold hover:bg-pink-300 transition-colors">
              Get Started
            </button>
          </a>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-purple-800 py-4 text-center">
        <p>Our service contains more than 50,894 objects in our database</p>
        <div className="mt-2">
          <a href="/privacy-policy" className="text-white underline">Privacy Policy</a> | <a href="/terms-of-service" className="text-white underline">Terms of Service</a>
        </div>
      </footer>

      {/* Кнопка Try Now на мобильных устройствах */}
      {isScrolled && !isHidden && !isPastFreeSection && (
        <div className="fixed bottom-0 left-0 right-0 bg-purple-600 text-white p-4 flex justify-center items-center md:hidden">
          <a href="https://app.estaters.online/">
            <button className="bg-pink-200 text-purple-700 px-6 py-3 rounded-full text-lg font-semibold flex items-center hover:bg-pink-300 transition-colors">
              Try Now <ArrowRight className="ml-2" />
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

const IconAnimation = ({ Icon, className, delay }) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsAnimated(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <div
      className={`absolute ${className} transition-transform duration-1000 ease-in-out transform ${
        isAnimated ? 'translate-y-0 opacity-80' : 'translate-y-5 opacity-0'
      } z-10`}
    >
      <Icon className="text-pink-200 w-12 h-12 md:w-16 md:h-16 opacity-50" />
    </div>
  );
};

const BenefitCard = ({ icon, title, description }) => (
  <div className="bg-purple-600 p-6 rounded-lg text-center transform transition-all duration-300 hover:scale-105 hover:shadow-lg">
    <div className="text-4xl mb-4 flex justify-center">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Router>
  );
};

export default App;
