import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="bg-purple-700 min-h-screen text-white">
      <header className="p-4 flex justify-between items-center md:justify-start">
        <Link to="/">
          <img src="/img/logo.png" alt="CHAMA Logo" className="h-10 md:ml-8" />
        </Link>
      </header>

      <main className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        <p className="text-sm text-gray-300 mb-2">Last Updated: 01/09/2024</p>

        <p className="mb-6">
          Welcome to Chama! Your privacy is important to us, and we are committed to protecting your personal information. 
          This Privacy Policy outlines how we collect, use, and safeguard your data when you use our service, 
          which specializes in curating real estate listings and generating personalized PDF catalogs.
        </p>

        <h2 className="text-2xl font-bold mb-4">1. Information We Collect</h2>
        <h3 className="text-xl font-bold mb-2">a. Personal Information</h3>
        <p className="mb-4">
          When you use our service, we may collect the following personal information:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Name: To personalize your experience and the generated PDF files.</li>
          <li>Email Address: To send the generated PDF files and any other communications related to our service.</li>
          <li>Location Preferences: To provide you with relevant real estate listings.</li>
          <li>Investment Preferences: To tailor the real estate options according to your financial goals.</li>
        </ul>

        <h3 className="text-xl font-bold mb-2">b. Non-Personal Information</h3>
        <p className="mb-4">
          We may also collect non-personal information, such as:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Browser Information: To optimize our website for your device.</li>
          <li>Usage Data: To improve our service by analyzing user behavior and preferences.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">2. How We Use Your Information</h2>
        <p className="mb-6">
          We use the information we collect for the following purposes:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Service Delivery: To generate and send personalized PDF files containing real estate listings based on your preferences.</li>
          <li>Communication: To send you updates, newsletters, or any information related to our services.</li>
          <li>Improvement and Development: To understand how our service is used and make improvements.</li>
          <li>Legal Compliance: To comply with legal obligations and protect our rights.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">3. Sharing Your Information</h2>
        <p className="mb-6">
          We do not sell or rent your personal information to third parties. However, we may share your data in the following circumstances:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website and delivering our services (e.g., email providers).</li>
          <li>Legal Obligations: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">4. Data Security</h2>
        <p className="mb-6">
          We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.
        </p>

        <h2 className="text-2xl font-bold mb-4">5. Your Rights</h2>
        <p className="mb-6">
          You have the right to:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Access: Request a copy of the personal information we hold about you.</li>
          <li>Correction: Request the correction of inaccurate or incomplete personal information.</li>
          <li>Deletion: Request the deletion of your personal information, subject to legal obligations.</li>
          <li>Withdrawal of Consent: Withdraw your consent for us to process your personal information at any time.</li>
        </ul>
        <p className="mb-6">
          To exercise these rights, please contact us at chamadubai@estaters.online.
        </p>

        <h2 className="text-2xl font-bold mb-4">6. Cookies and Tracking Technologies</h2>
        <p className="mb-6">
          We use cookies and similar tracking technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.
        </p>

        <h2 className="text-2xl font-bold mb-4">7. Changes to This Privacy Policy</h2>
        <p className="mb-6">
          We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the "Last Updated" date.
        </p>

        <h2 className="text-2xl font-bold mb-4">8. Contact Us</h2>
        <p className="mb-6">
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </p>
        <p className="mb-6">
          Chama<br />
          chamadubai@estaters.online<br />
          Golden Mile 8 - 8 Palm Jumeirah Road - Palm Jumeirah - Dubai
        </p>
      </main>

      <footer className="bg-purple-800 py-4 text-center">
        <p>&copy; 2024 Chama. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
